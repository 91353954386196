import { Box, Toolbar, Typography,Grid,Modal ,Card, TextField, Button, Divider ,Checkbox,Paper} from '@mui/material'
import { Container } from '@mui/system'
import React, { Component } from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Horrizontalscroll from './Horrizontalscroll';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import SearchIcon from '@mui/icons-material/Search';
import Mobilehorrizontal from './Mobilehorrizontal';
import BookIcon from '@mui/icons-material/Book';
import firebase from "../src/firebase"
import { useNavigate,useLocation } from 'react-router-dom';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AddHomeIcon from '@mui/icons-material/AddHome';
import Groups2Icon from '@mui/icons-material/Groups2';
import LocationCityIcon from '@mui/icons-material/LocationCity';

import boy from "../src/img/boy.png"
import girl from "../src/img/girl.png"

import CloseIcon from '@mui/icons-material/Close';
import BoyIcon from '@mui/icons-material/Boy';

import imgone from "../src/img/imgtwo.webp"
import tt from "../src/img/tt.webp"
import ttt from "../src/img/ttt.webp"
import Scroller from './Scrolller';
import backg from "../src/img/backg.jpg"
import Geocode from "react-geocode";
import map2 from "../src/img/map2.png"
import LinkedInIcon from '@mui/icons-material/LinkedIn';
//import firebase from "../src/firebase"

/////// image from  ////////////////////
import android_img  from '../src/img_folder/andoid_app.jpg'
import android_ecommerce_dev from '../src/img_folder/ecommerce_app.webp'
import ios_app from '../src/img_folder/ios_app.jpg'
import crm from '../src/img_folder/crm.jpg'
import erp from '../src/img_folder/erp.jpg'
import website from '../src/img_folder/website.jpg'






export class Pageone extends Component {
constructor(props) {
  super(props)
  
  this.state = {
    city:"",
    data:[],


    form_open : false,
    name  :"",
    email_id  : "",
    phone_no  : ""
  }
  this.handleChange = this.handleChange.bind(this)

}

handleChange=(e)=>{
  this.setState({
    [e.target.name] : e.target.value
  })
}



  componentDidMount(){
    window.gtag('config', 'AW-328229914');

    window.gtag('event', 'conversion', {'send_to': 'AW-328229914/dn24CMq_3LoZEJrIwZwB'});
 
    
  }









///////

















renderMenu(){
    return(
      <Box sx={{ height:100,backgroundColor:'blue'}}>
    
      </Box>
     )
  }




///18.573768392536493, 73.91063504803198



//18.566621645590683, 73.91230310584619








save=()=>{

  if(this.state.email_id!=="" && this.state.name!=="" && this.state.phone_no!==""){
    const key= firebase.database().ref("contactform").push().key
   firebase.database().ref().child('FormData').child(key).set({
    username:  this.state.name ,
    email: this.state.email_id,
    phone :this.state.phone_no
  }).then(()=>{

alert("Enquiry Done");
this.setState({
form_open  :false,
name:"",
email_id  :"",
phone_no:""
})

  })
  }else{

    alert('Fill All Fields');

  }
 
}








  render() {
    return (
      <div>
     
      <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3}}}>
      <Container maxWidth='xl'>
    <Box sx={{minHeight:400}}>
    <Grid container spacing={1}>
    <Grid item xs={12} sm={12} md={6} lg={6}>
     <Box sx={{backgroundColor:'#ffffff',height:'100%'}}>
     <br/>
     <br/>
<Typography variant='h1' sx={{fontSize:{xs:33,sm:45,md:45,lg:45},textAlign:{xs:'center',sm:'center',md:'left',lg:'left'},fontWeight:'950',mt:{xs:3,sm:13},color:'#c7167a'}}>India's Premier<br/> Public IT<br/> Brand.</Typography>
<Typography variant='h6' sx={{fontSize:{xs:18,sm:26},mt:2,fontWeight:'500',color:'black',textAlign:{xs:'center',sm:'center',md:'left',lg:'left'},}}>Award Winning Customers Experience</Typography>

<Card sx={{display:'flex',flexDirection:'row',mt:7,height:60,borderRadius:10,mr:{xs:'0%',sm:'0%',md:'20%',lg:'20%'},borderBottom:5,borderBottomColor:'#f0f0f0',borderTop:1,borderTopColor:'#7b7b7b',justifyContent:'space-between'}}>

<Box sx={{display:'flex',alignItems:'center',ml:2}}>
<LocationOnIcon sx={{color:'#c7167a'}}/>
<Box>
<TextField

         sx={{width:'100%',outline:0,ml:2}}
         placeholder="Search location"
          id="outlined-error"
          variant='standard'
          onChange={this.handleChange}
          value={this.state.city}
          InputProps={{
            disableUnderline:true,
            
          }}
        />
</Box>
</Box>
<Box sx={{display:'flex',alignItems:'center'}}>

<Box onClick={this.mm} sx={{ display:'flex',justifyContent:'center',backgroundColor:'#e1e9e9',height:35,mr:5,width:90,borderRadius:7}}>
<Box sx={{display:'flex',alignItems:'center'}}>
<MyLocationIcon sx={{height:15,width:15,mr:1}}/>

<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{fontSize:12}}>Near me</Typography>
</Box>
</Box>
</Box>

<Box sx={{height:45,width:45,backgroundColor:'#c7167a',display:'flex',justifyContent:'center',borderRadius:7,mr:1}}>
<Box sx={{display:'flex',alignItems:'center'}}>
<SearchIcon sx={{color:'white',display:'flex',alignItems:'center'}}/>
</Box>
</Box>



</Box>
</Card>


     </Box>
    </Grid>
    <Grid item xs={12} sm={12} md={6} lg={6}>
    <Box sx={{minHeight:401,mt:{xs:0,sm:6}}}>
    <Box sx={{display:{xs:'none',sm:'flex',},mt:12,justifyContent:'right'}}>
     <Horrizontalscroll/>
    </Box>
    <Box sx={{display:{xs:'flex',sm:'none'},justifyContent:'right'}}>
    <Mobilehorrizontal/>
   </Box>
    </Box>
    </Grid>
  </Grid>
    </Box>
    


<Box sx={{backgroundColor:'#f8f6f6',minHeight:100,mt:2,borderRadius:4}}>
<Grid item xs={12} sm={12} md={12} lg={12}>
<br/>
<Box sx={{maxHeight:300}}>
<Card sx={{maxHeight:200,backgroundColor:'white',borderRadius:5,m:2,mb:2}}>
<Typography color='black' align='center' sx={{fontSize:{xs:19,sm:19,md:22},fontFamily:'serif'}} fontWeight='bold' mt={2}>30+ Years Of Legacy </Typography>

<Grid container spacing={1}>
<Grid item xs={4} sm={4} md={4} lg={4}>


<Box sx={{display:'flex',justifyContent:'center'}}>
<Box sx={{display:'flex',flexDirection:'column',alignItems:'center'}}>
<LocationCityIcon sx={{height:{xs:35,sm:35,md:50},width:{xs:25,sm:25,md:50},color:'#00337b'}}/>
<Typography align='center' sx={{fontSize:{xs:12,sm:12,md:16},fontFamily:'sans-serif',fontWeight:'bold',}}>+22</Typography>
<Typography align='center' sx={{fontSize:{xs:12,sm:12,md:16},fontFamily:'sans-serif',fontWeight:'bold',}}>Cities</Typography>
</Box>
</Box>
</Grid>
<Grid item xs={4} sm={4} md={4} lg={4}>
<Box sx={{display:'flex',justifyContent:'center'}}>
<Box sx={{display:'flex',flexDirection:'column',alignItems:'center'}}>
<AddHomeIcon sx={{height:{xs:35,sm:35,md:50},width:{xs:25,sm:25,md:50},color:'#00337b'}}/>
<Typography align='center' sx={{fontSize:{xs:12,sm:12,md:16},fontFamily:'sans-serif',fontWeight:'bold'}}>+300</Typography>
<Typography align='center' sx={{fontSize:{xs:12,sm:12,md:16},fontFamily:'sans-serif',fontWeight:'bold',}}>Happy Customers</Typography>
</Box>
</Box>
</Grid>
<Grid item xs={4} sm={4} md={4} lg={4}>
<Box sx={{display:'flex',justifyContent:'center'}}>
<Box sx={{display:'flex',flexDirection:'column',alignItems:'center'}}>
<Groups2Icon sx={{height:{xs:35,sm:35,md:50},width:{xs:25,sm:25,md:50},color:'#00337b'}}/>
<Typography align='center' sx={{fontSize:{xs:12,sm:12,md:16},fontFamily:'sans-serif',fontWeight:'bold',}}>+500</Typography>
<Typography align='center' sx={{fontSize:{xs:12,sm:12,md:16},fontFamily:'sans-serif',fontWeight:'bold',}}>Project</Typography>
</Box>
</Box>
</Grid>
</Grid>
<br/>
</Card>
<br/>
 </Box>
</Grid>
</Box>









<Box sx={{minHeight:200}}>
<br/>
<Typography variant='h1' sx={{fontSize:{xs:20,sm:22,md:27,lg:27},fontWeight:'600',mt:2,ml:2}}>Popular Services</Typography>
<Box sx={{m:2}}>
      <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={4}>
      <Card sx={{height:450,width:'100%' }}>
      <Box
      component="img"
      sx={{
        height:220,
        display: 'block',
      objectFit:'cover',
        overflow: 'hidden',
        width: '100%',
      }}
      src={android_img}
      alt=''
    />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{fontSize:18,fontWeight:'800',color:'#c7167a',mt:2}}>
        Android App Devlopment
        </Typography>

        <Box sx={{display:'flex'}}>
 <img src={girl} style={{height:30,width:30,marginLeft:3}}/>

</Box>


        <Typography variant="body2" color="text.secondary" sx={{mt:2,color:'black',fontWeight:'600'}}>
        Android software development is the process by which applications are created for devices running the Android operating system.
        </Typography>
      </CardContent>
      <CardActions >
    
        <Button  onClick={()=>{this.setState({form_open:true})}}  size="small" variant='contained'  endIcon={<BookIcon  sx={{color:'white'}}/>} sx={{backgroundColor:'#c7167a'}}>Inquire Now</Button>
      
        
      </CardActions>


    </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
     
      <Card sx={{height:450,width:'100%' }}>
      <Box
      component="img"
      sx={{
        height:220,
        display: 'block',
      objectFit:'cover',
        overflow: 'hidden',
        width: '100%',
      }}
      src={android_ecommerce_dev}
      alt=''
    />
      <CardContent>
      <Typography gutterBottom variant="h5" component="div" sx={{fontSize:18,fontWeight:'800',color:'#c7167a',mt:2}}>
      Android Ecommerce App Devlopment
      </Typography>
      <Box sx={{display:'flex'}}>
 <img src={boy} style={{height:30,width:30,marginLeft:3}}/><img src={girl} style={{height:30,width:30,marginLeft:3}}/>
</Box>

      <Typography variant="body2" color="text.secondary" sx={{mt:2,color:'black',fontWeight:'600'}}>
 it is a software program that lets users buy and sell goods or services online
      </Typography>
      </CardContent>
      <CardActions sx={{display:'flex',position:'static', bottom:1}}>
        <Button   onClick={()=>{this.setState({form_open:true})}}  size="small" variant='contained'  endIcon={<BookIcon  sx={{color:'white'}}/>} sx={{backgroundColor:'#c7167a'}}>Inquire Now</Button>
      
        </CardActions>
    </Card>
      </Grid>
    
      <Grid item xs={12} sm={12} md={4}>
     
      <Card sx={{height:450,width:'100%' }}>
      <Box
      component="img"
      sx={{
      
        height:220,
        display: 'block',
      objectFit:'cover',
        overflow: 'hidden',
        width:'100%',
      }}
      src={ios_app}
      alt=''
    />
      <CardContent>
      <Typography gutterBottom variant="h5" component="div" sx={{fontSize:18,fontWeight:'800',color:'#c7167a',mt:2}}>
       IOS App Devlopment
      </Typography>

<Box sx={{display:'flex'}}>

<img src={boy} style={{height:30,width:30}}/> 

</Box>


      <Typography variant="body2" color="text.secondary" sx={{mt:1,color:'black',fontWeight:'600'}}>
     A app or software that run on ios device.
      </Typography>
      </CardContent>
      <CardActions sx={{display:'flex',position:'relative',bottom:0}} >
      
        <Button  onClick={()=>{this.setState({form_open:true})}}   size="small" variant='contained'  endIcon={<BookIcon  sx={{color:'white'}}/>} sx={{backgroundColor:'#c7167a'}}>Inquire Now</Button>
      
      </CardActions>
    </Card>
      </Grid>
    </Grid>

    </Box>
</Box>



















<Box sx={{minHeight:200}}>
<br/>
<Typography variant='h1' sx={{fontSize:{xs:20,sm:22,md:27,lg:27},fontWeight:'600',mt:2,ml:2}}>Other Services</Typography>
<Box sx={{m:2}}>
      <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={4}>
      <Card sx={{height:475,width:'100%' }}>
      <Box
      component="img"
      sx={{
        height:220,
        display: 'block',
      objectFit:'cover',
        overflow: 'hidden',
        width: '100%',
      }}
      src={crm}
      alt=''
    />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{fontSize:18,fontWeight:'800',color:'#c7167a',mt:2}}>
       CRM Devlopment
        </Typography>

        <Box sx={{display:'flex'}}>
 <img src={girl} style={{height:30,width:30,marginLeft:3}}/>

</Box>
       

        <Typography variant="body2" color="text.secondary" sx={{mt:2,color:'black',fontWeight:'600'}}>
        CRM stands for enterprise resource planning. It's a software system that helps organizations automate and manage core business processes
        </Typography>
      </CardContent>
      <CardActions >
    
        <Button  onClick={()=>{this.setState({form_open:true})}}  size="small" variant='contained'  endIcon={<BookIcon  sx={{color:'white'}}/>} sx={{backgroundColor:'#c7167a'}}>Inquire Now</Button>
        
      </CardActions>


    </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
     
      <Card sx={{height:475,width:'100%' }}>
      <Box
      component="img"
      sx={{
        height:220,
        display: 'block',
      objectFit:'cover',
        overflow: 'hidden',
        width: '100%',
      }}
      src={erp}
      alt=''
    />
      <CardContent>
      <Typography gutterBottom variant="h5" component="div" sx={{fontSize:18,fontWeight:'800',color:'#c7167a',mt:2}}>
       ERP Software devlopment
      </Typography>
<Box sx={{display:'flex'}}>
 <img src={girl} style={{height:30,width:30,marginLeft:3}}/>
</Box>
      <Typography variant="body2" color="text.secondary" sx={{mt:2,color:'black',fontWeight:'600'}}>
      ERP stands for enterprise resource planning. It's a software system that helps organizations automate and manage core business processes
      </Typography>
      </CardContent>
      <CardActions sx={{display:'flex',position:'static', bottom:1}}>
        <Button size="small"  onClick={()=>{this.setState({form_open:true})}}  variant='contained'  endIcon={<BookIcon  sx={{color:'white'}}/>} sx={{backgroundColor:'#c7167a'}}>Inquire Now</Button>
      
        </CardActions>
    </Card>
      </Grid>
    
      <Grid item xs={12} sm={12} md={4}>
     
      <Card sx={{height:475,width:'100%' }}>
      <Box
      component="img"
      sx={{
      
        height:220,
        display: 'block',
      objectFit:'cover',
        overflow: 'hidden',
        width:'100%',
      }}
      src={website}
      alt=''
    />
      <CardContent>
      <Typography gutterBottom variant="h5" component="div" sx={{fontSize:18,fontWeight:'800',color:'#c7167a',mt:2}}>
      Website Devlopment
      </Typography>

<Box sx={{display:'flex'}}>
 <img src={boy} style={{height:30,width:30,marginLeft:3}}/>

</Box>


      <Typography variant="body2" color="text.secondary" sx={{mt:1,color:'black',fontWeight:'600'}}>
      A website is a collection of web pages and related content that is identified by a common domain name and published on at least one web server
      </Typography>
      </CardContent>
      <CardActions sx={{display:'flex',position:'relative',bottom:0}} >
      
        <Button size="small" variant='contained' onClick={()=>{this.setState({form_open:true})}}   endIcon={<BookIcon  sx={{color:'white'}}/>} sx={{backgroundColor:'#c7167a'}}>Inquire Now</Button>
      
      </CardActions>
    </Card>
      </Grid>
    </Grid>

    </Box>
</Box>

<br/>











































































<Typography variant='h1' sx={{fontSize:{xs:20,sm:22,md:25,lg:25},fontWeight:'600',mt:2,ml:2,mt:2}}>Our Customers</Typography>
<Box sx={{backgroundColor:'#f5f3f3'}}>
<Scroller/>
</Box>





<br/>


<Box sx={{backgroundImage: `url(${map2})`,minHeight:300,backgroundColor:'#022246 ',opacity:0.9}}>
<Box sx={{ml:{xs:'0%',sm:'2%'},mr:{xs:'0%',sm:'2%'}}}>
<br/>
<Typography align='justify' variant='h1'  sx={{m:1,mb:2,fontWeight:'545',fontFamily:'sans-serif',color:'white',fontSize:{xs:22,sm:22,md:30,lg:30},}}>Who we are ?.</Typography>
<Divider/>
<Typography align='justify'  sx={{m:1,mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:13,sm:13,md:15,lg:15}}}>
INCC Services is an IT services, consulting and business solutions organization that has been partnering with many of the world’s largest businesses for the past 10 years. We believe innovation and collective knowledge can transform all our futures with greater purpose.
</Typography>
<Typography align='justify'  sx={{m:1,mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:13,sm:13,md:15,lg:15}}}>INCC Building greater futures through innovation and collective knowledge.</Typography>
<Typography align='justify'  sx={{m:1,mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:13,sm:13,md:15,lg:15}}}>INCC is seen as a benchmark in its outreach to investors, in its transparency and disclosures, publicly communicating its strategy, risks and opportunities, reducing information asymmetries and enabling fair valuation of the stock.</Typography>
<br/>


</Box>
</Box>







</Container>
</Box>








<Box>
<Modal
  open={this.state.form_open}
  onClose={this.handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',height:'100%',width:'100%'}}>
<Paper sx={{width:{xs:'90%',sm:'90%',md:'70%',lg:'30%'},height:'90vh',backgroundColor:'white',borderRadius:2}}>

<Box sx={{width:'100%',display:'flex',justifyContent:'right'}}>
<Paper onClick={()=>this.setState({
  form_open:false,
})} elevation={5} sx={{height:30,width:30,borderRadius:1,backgroundColor:'#fff',display:'flex',justifyContent:'center',alignItems:'center',mr:-1,mt:-1}}>
<CloseIcon sx={{height:20,width:20,color:'#2486bb'}}/>
</Paper>
</Box>



<Typography sx={{fontSize:18,fontWeight:'600',paddingLeft:{xs:2,sm:4},mb:2}}>Enquire Now</Typography>
<Box sx={{paddingLeft:{xs:2,sm:4},paddingRight:{xs:2,sm:4},overflowY:'scroll',maxHeight:'80vh'}}>

<Typography sx={{fontSize:12,fontWeight:'600',padding:0.2,mt:1,display:'flex',flexDirection:'row'}}>Name<Typography sx={{color:'red'}}>*</Typography></Typography>
<TextField InputProps={{sx:{fontSize:12,fontWeight:'600'}}} onChange={this.handleChange}  value={this.state.name} name="name" fullWidth size='small'/>


<Typography sx={{fontSize:12,fontWeight:'600',padding:0.2,mt:1,display:'flex',flexDirection:'row'}}>Phone No<Typography sx={{color:'red'}}>*</Typography></Typography>
<TextField InputProps={{sx:{fontSize:12,fontWeight:'600'}}} onChange={this.handleChange}  value={this.state.phone_no} name="phone_no" fullWidth size='small'/>


<Typography sx={{fontSize:12,fontWeight:'600',padding:0.2,mt:1,display:'flex',flexDirection:'row'}}>Email Id<Typography sx={{color:'red'}}>*</Typography></Typography>
<TextField InputProps={{sx:{fontSize:12,fontWeight:'600'}}} onChange={this.handleChange}  value={this.state.email_id} name="email_id" fullWidth size='small'/>



<Button variant='contained' onClick={this.save} disableElevation size='small' sx={{backgroundColor:'#2486bb',mt:2,textTransform:'none'}}>
  save
</Button>

<br/>
<br/>
<br/>
</Box>
</Paper>
  </Box>
</Modal>
</Box>

      </div>
    )
  }
}



export function Pageonec(props){
  const navigate = useNavigate();
  const location = useLocation();
  return (<Pageone navigate={navigate}></Pageone>)
}





export default Pageone